import React from "react";
import "./Step.scss";
import Bar from "../components/Bar";
import Layout from "../components/Layout";
import ImgStep0301 from "../images/step0301.png";
import ImgStep0302 from "../images/step0302.png";
import ImgStep0303 from "../images/step0303.png";

// Path
const step02Path = "/Step02";
const step04Path = "/Step04";

const Step03Page = () => {
  return (
    <Layout
      step="3"
      returnHref={step02Path}
      nextHref={step04Path}
      returnDataColor="secondary"
      nextDataColor="primary"
      dataCheck01="done"
      dataCheck02="done"
      dataCheck03="wip"
      dataCheck04=""
    >
      <Bar number="3" text="デスク脚を選択してください" />

      <div className="section-lead">
        <p>使用しているチェアのタイプや見た目で選ぶのがおすすめです。</p>
      </div>

      <div className="section-inner">
        <div className="card">
          <span className="card-title">４本脚</span>
          <p className="card-lead">
            スタンダードな脚の形で、安定感があるタイプです。どんなチェアにも合わせることができます。また、すっきりとしたシルエットで多くの方にとって使いやすい脚です。
          </p>
          <div className="card-img">
            <img src={ImgStep0301} alt="sample" />
          </div>
          <ul className="card-feature">
            <li>特徴</li>
            <li>４本脚の特徴やメリット・デメリット</li>
            <li>４本脚の特徴やメリット・デメリット</li>
            <li>４本脚の特徴やメリット・デメリット</li>
          </ul>
        </div>

        <div className="card">
          <span className="card-title">スクエア脚</span>
          <p className="card-lead">
            短辺側にも柱があり、４本脚に比べて対荷重・強度に優れています。シンプルなデザインですが高級感があるため、４本脚では少し物足りない人におすすめの脚です。
          </p>
          <div className="card-img">
            <img src={ImgStep0302} alt="sample" />
          </div>
          <ul className="card-feature">
            <li>特徴</li>
            <li>スクエア脚の特徴やメリット・デメリット</li>
            <li>スクエア脚の特徴やメリット・デメリット</li>
            <li>スクエア脚の特徴やメリット・デメリット</li>
          </ul>
        </div>
        <div className="card">
          <span className="card-title">T字型脚</span>
          <p className="card-lead">
            T字を逆さまにした形が特徴の脚です。一見安定感がなさそうに見えますが、対荷重・強度共に優れており、足元が広いので回転するチェアとも相性が良い脚です。
          </p>
          <div className="card-img">
            <img src={ImgStep0303} alt="sample" />
          </div>
          <ul className="card-feature">
            <li>特徴</li>
            <li>T字脚の特徴やメリット・デメリット</li>
            <li>T字脚の特徴やメリット・デメリット</li>
            <li>T字脚の特徴やメリット・デメリット</li>
          </ul>
        </div>
      </div>
      <div className="section-btn">
        <input type="radio" name="radio" id="radio1" />
        <label for="radio1">４本脚</label>
        <input type="radio" name="radio" id="radio2" />
        <label for="radio2">スクエア脚</label>
        <input type="radio" name="radio" id="radio3" />
        <label for="radio3">T字型脚</label>
        <p>種類を選択し、「次へ」を押してください。</p>
      </div>
    </Layout>
  );
};

export default Step03Page;
